/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import loading from "./loading.svg";
import appstore_ios from "./appstore-ios.svg";
import appstore_android from "./appstore-android.svg";
import "./App.css";

const iosLink =
  "http://phobos.apple.com/WebObjects/MZFinance.woa/wa/freeProductCodeWizard?code=";
const androidLink = "https://play.google.com/redeem?code=";

const iosStoreListing =
  "https://apps.apple.com/us/app/foxtrot-hangboard-trainer/id1589712393";
const androidStoreListing =
  "https://play.google.com/store/apps/details?id=com.foxtrotclimbing.app";

interface AppProps {
  platform: string | null;
}

export const App: React.FC<AppProps> = (props: AppProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const [status, setStatus] = useState(0);
  const [code, setCode] = useState("");
  const [codeLink, setCodeLink] = useState("#");

  const getNextCode = async () => {
    if (props.platform) {
      setIsFetching(true);
      try {
        const res = await fetch(`/api/codes?platform=${props.platform}`);
        if (res.status === 200) {
          const code = await res.json();
          setCode(code);
        }
        setStatus(res.status);
      } catch (e) {
        setStatus(400);
      } finally {
        setIsFetching(false);
      }
    }
  };

  const markCodeAsUsed = async () => {
    try {
      const body = JSON.stringify({
        code,
      });
      await fetch("/api/codes", {
        method: "PUT",
        body,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const getAppStoreLogo = () => {
    if (props.platform === "ios") {
      return appstore_ios;
    } else if (props.platform === "android") {
      return appstore_android;
    }
  };

  const getAppStoreListing = () => {
    if (props.platform === "ios") {
      return iosStoreListing;
    } else if (props.platform === "android") {
      return androidStoreListing;
    }
  };

  useEffect(() => {
    getNextCode();
  }, []);

  useEffect(() => {
    setCodeLink(buildAppStoreLink(code, props.platform));
  }, [code, props.platform]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {!props.platform && (
          <>
            <p>Pick a platform!</p>
            <a className="App-link" href={"?p=ios"}>
              iOS
            </a>
            <a className="App-link" href={"?p=android"}>
              Android
            </a>
          </>
        )}
        {isFetching && (
          <img src={loading} className="app-store-logo" alt="app-store" />
        )}
        {props.platform && status === 200 && !isFetching && (
          <>
            <div className="message">
              <p>Click below to download the app</p>
              <p>(for free!)</p>
            </div>
            <a
              className="App-link"
              href={codeLink}
              onClick={markCodeAsUsed}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={getAppStoreLogo()}
                className="app-store-logo"
                alt="app-store"
              />
            </a>
          </>
        )}
        {status === 204 && !isFetching && (
          <>
            <div className="message">
              <p>Sorry!</p>
              <p>There are no more promo codes available for this platform!</p>
              <p>
                You can try again later or follow the link below to buy the app.
                (it's only $1!)
              </p>
            </div>
            <a
              className="App-link"
              href={getAppStoreListing()}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={getAppStoreLogo()}
                className="app-store-logo"
                alt="app-store"
              />
            </a>
          </>
        )}
      </header>
    </div>
  );
};

const buildAppStoreLink = (code: string, platform: string | null): string => {
  switch (platform) {
    case "ios":
      return `${iosLink}${code}`;
    case "android":
      return `${androidLink}${code}`;
    default:
      return "#";
  }
};
