import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

const getPlatform = (): string | null => {
  const params = new URLSearchParams(window.location.search);
  return params.get("p");
};

ReactDOM.render(
  <React.StrictMode>
    <App platform={getPlatform()} />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
